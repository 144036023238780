<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("DriverList.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fas fa-user-tie</v-icon>
    </v-toolbar>

    <v-btn small class="my-2" color="success" @click.stop="openCreateDialog()">
      {{ $t("btnCreate") }}
    </v-btn>
    <import-data-csv
      :column-options="importColumnOptions"
      @imported="handleImport($event)"
      v-show="displayOnlyForAdmin"
    />
    <v-row class="my-1" dense align="center">
      <v-col cols="12" md="2">
        <v-switch
          v-model="enabledDrivers"
          :label="$t('DriverList.enabledDriversSwitch')"
          dense
          small
        ></v-switch>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn small color="info" @click="getAll()">
          <v-icon left dark small> fa-redo-alt </v-icon>
          {{ $t("btnUpdate") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      class="elevation-1"
      sort-by="lastName"
      :mobile-breakpoint="mobileBreakpointTable"
      dense
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, 250, -1],
        showFirstLastPage: true,
      }"
      ><!-- Filtrovanie tabulky -->
      <template
        v-slot:[`body.prepend`]
        v-if="$vuetify.breakpoint.width > mobileBreakpointTable"
      >
        <tr>
          <td>
            <v-icon class="px-3">mdi-filter</v-icon>
          </td>
          <td>
            <v-text-field
              v-model="filter.email"
              type="text"
              clearable
              :label="$t('DriverList.filterLabel.email')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.internalName"
              type="text"
              clearable
              :label="$t('DriverList.filterLabel.internalName')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.firstName"
              type="text"
              clearable
              :label="$t('DriverList.filterLabel.firstName')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.lastName"
              type="text"
              clearable
              :label="$t('DriverList.filterLabel.lastName')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.phone"
              type="text"
              clearable
              :label="$t('DriverList.filterLabel.phone')"
            ></v-text-field>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <template v-slot:[`body.prepend`] v-else>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.email"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('DriverList.filterLabel.email')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.internalName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('DriverList.filterLabel.internalName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.firstName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('DriverList.filterLabel.firstName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.lastName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('DriverList.filterLabel.lastName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.phone"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('DriverList.filterLabel.phone')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.validFrom`]="{ item }">
        <span>{{ $options.filters.formatDateLong(item.validFrom) }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-icon v-if="item.status == 'ENABLED'" color="green">
          mdi-checkbox-marked-circle
        </v-icon>
        <v-icon v-else-if="item.status == 'DISABLED'" color="red">
          mdi-cancel
        </v-icon>
        <v-icon v-else color="grey"> mdi-checkbox-blank-circle-outline </v-icon>
        <!--<v-chip
          small
          :color="getStatusColor(item.status)"
          class="px-1"
          dark
          label
        >
          {{ item.status }}
        </v-chip>-->
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-icon v-if="item.active == 'ACTIVE'" color="green">
          mdi-checkbox-marked-circle
        </v-icon>
        <v-icon v-else-if="item.active == 'DISABLED'" color="red">
          mdi-cancel
        </v-icon>
        <v-icon v-else color="grey"> mdi-checkbox-blank-circle-outline </v-icon>
        <!--<v-chip
          small
          :color="getActiveColor(item.active)"
          class="px-1"
          dark
          label
        >
          {{ item.active }}
        </v-chip>-->
      </template>

      <template v-slot:[`item.workStatus`]="{ item }">
        <v-chip
          small
          :color="getWorkStatusColor(item.workStatus)"
          class="px-1"
          dark
          label
        >
          {{ $t("workStatus." + item.workStatus) }}
        </v-chip>
      </template>

      <template v-slot:[`item.silence`]="{ item }">
        <v-icon
          v-if="item.silence"
          color="red"
          :title="$t('DriverList.silenceEnabledTitle')"
        >
          mdi-bell-off
        </v-icon>
        <v-icon
          v-else
          color="grey"
          :title="$t('DriverList.silenceDisabledTitle')"
        >
          mdi-bell
        </v-icon></template
      >
      <template v-slot:[`item.dispatcher`]="{ item }">
        <v-icon v-if="item.dispatcher" color="green"> mdi-headset </v-icon>
        <v-icon v-else color="grey"> mdi-headset-off </v-icon></template
      >
      <template v-slot:[`item.external`]="{ item }">
        <v-icon v-if="item.external" color="orange"> mdi-handshake </v-icon>
        <v-icon v-else color="grey"> mdi-handshake-outline </v-icon></template
      >

      <template v-slot:[`item.licenceStatus`]="{ item }">
        <v-icon v-if="item.licenceStatus == 'OK'" color="green">
          mdi-checkbox-marked-circle
        </v-icon>
        <v-icon v-else-if="item.licenceStatus == 'NOKD'" color="red">
          mdi-alert-circle
        </v-icon>
        <v-icon v-else-if="item.licenceStatus == 'NOKT'" color="red">
          mdi-alert-octagon
        </v-icon>
        <v-icon v-else color="grey"> mdi-checkbox-blank-circle-outline </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon small @click="detail(item.id)">fa-eye</v-icon> -->
        <!-- <v-icon small @click="deleteTutorial(item.id)">mdi-delete</v-icon> -->
        <v-icon
          small
          class="ml-2"
          @click="openEditDialog(item.id)"
          :title="$t('DriverList.btnEdit')"
        >
          mdi-square-edit-outline</v-icon
        >
        <v-icon
          small
          class="ml-2"
          @click="openEditStatusDialog(item.id)"
          :title="$t('DriverList.btnEditStatus')"
        >
          mdi-account-check-outline</v-icon
        >
        <v-icon
          v-show="displayOnlyForAdmin"
          small
          class="ml-2"
          @click="openEditPasswordDialog(item.id)"
          :title="$t('DriverList.btnPasswordChange')"
          >mdi-lock-reset</v-icon
        >
      </template>
    </v-data-table>

    <!-- Dialog vytvorenie noveho vodica -->
    <v-dialog
      v-model="showCreateDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("DriverList.dialogCreateTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreate">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    prepend-inner-icon="fa-envelope"
                    :label="$t('DriverList.email')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DriverList.password')"
                    prepend-inner-icon="fa-lock"
                    filled
                    :rules="passwordRules"
                    v-model="createDialogData.item.password"
                    required
                    :append-icon="
                      createDialogData.showPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :type="createDialogData.showPassword ? 'text' : 'password'"
                    append-outer-icon="fa-random"
                    @click:append="
                      createDialogData.showPassword =
                        !createDialogData.showPassword
                    "
                    @click:append-outer="
                      createDialogData.item.password = Math.random()
                        .toString(36)
                        .substr(2, 6)
                    "
                    autocomplete="new-password"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DriverList.firstName')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.firstName"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DriverList.lastName')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.lastName"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DriverList.internalName')"
                    filled
                    :rules="[
                      (v) => !!v || $t('msgRequiredField'),
                      (v) =>
                        (v && v.length <= 3) ||
                        'Internal name must be no more than 3 characters',
                    ]"
                    :counter="3"
                    hint="Maximum 3 characters (recommended: 2)"
                    v-model="createDialogData.item.internalName"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    prepend-inner-icon="fa-phone"
                    :label="$t('DriverList.phone')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.phone"
                    :placeholder="$t('phone_placeholder')"
                    :hint="$t('phone_hint')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    prepend-inner-icon="fa-phone"
                    :label="$t('DriverList.phoneExt')"
                    filled
                    v-model="createDialogData.item.phoneExt"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('DriverList.gender')"
                    filled
                    :items="$t('gender')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.gender"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('DriverList.tag')"
                    filled
                    :items="$t('driverTag')"
                    v-model="createDialogData.item.tag"
                    required
                  ></v-select>
                </v-col>

                <!-- <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    prepend-inner-icon="mdi-card-account-details-outline"
                    :label="$t('DriverList.driverLicenceNumber')"
                    filled
                    v-model="createDialogData.item.driverLicenceNumber"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <my-datepicker
                    v-model="createDialogData.item.driverLicenceExpiration"
                    :title="$t('DriverList.driverLicenceExpiration')"
                    :label="$t('DriverList.driverLicenceExpiration')"
                    filled
                  >
                  </my-datepicker>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    prepend-inner-icon="mdi-card-account-details-star-outline"
                    :label="$t('DriverList.driverTaxiLicenceNumber')"
                    filled
                    v-model="createDialogData.item.driverTaxiLicenceNumber"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <my-datepicker
                    v-model="createDialogData.item.driverTaxiLicenceExpiration"
                    :title="$t('DriverList.driverTaxiLicenceExpiration')"
                    :label="$t('DriverList.driverTaxiLicenceExpiration')"
                    filled
                  >
                  </my-datepicker>
                </v-col> -->

                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('DriverList.language1')"
                    filled
                    :items="$t('language')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createDialogData.item.language1"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('DriverList.language2')"
                    filled
                    :items="$t('language')"
                    v-model="createDialogData.item.language2"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('DriverList.language3')"
                    filled
                    :items="$t('language')"
                    v-model="createDialogData.item.language3"
                    required
                  ></v-select
                ></v-col>

                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('DriverList.language4')"
                    filled
                    :items="$t('language')"
                    v-model="createDialogData.item.language4"
                    required
                  ></v-select
                ></v-col>
                <!-- <v-col cols="12" md="6">
                  <v-checkbox
                    :label="$t('DriverList.dispatcher')"
                    v-model="createDialogData.item.dispatcher"
                    dense
                    filled
                  ></v-checkbox>
                </v-col> -->
              </v-row>
              <v-row>
                <v-col cols="12"
                  ><p>
                    {{ $t("DriverList.emailSameDriverDispatcherWarning") }}
                  </p></v-col
                ></v-row
              >
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="showCreateDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="submitCreateDialog()">
              {{ $t("btnCreate") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Dialog editaciu vodica -->
    <v-dialog
      v-model="showEditDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("DriverList.dialogEditTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formEdit">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" v-show="displayOnlyForAdmin">
                  <v-text-field
                    :label="$t('DriverList.id')"
                    v-model="editDialogData.id"
                    disabled
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    :label="$t('DriverList.email')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.email"
                    required
                    disabled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DriverList.firstName')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.firstName"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DriverList.lastName')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.lastName"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('DriverList.internalName')"
                    filled
                    :rules="[
                      (v) => !!v || $t('msgRequiredField'),
                      (v) =>
                        (v && v.length <= 3) ||
                        'Internal name must be no more than 3 characters',
                    ]"
                    :counter="3"
                    hint="Maximum 3 characters (recommended: 2)"
                    v-model="editDialogData.internalName"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    :label="$t('DriverList.dispatcher')"
                    v-model="editDialogData.dispatcher"
                    dense
                    filled
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    :label="$t('DriverList.external')"
                    v-model="editDialogData.external"
                    dense
                    filled
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    prepend-inner-icon="fa-phone"
                    :label="$t('DriverList.phone')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.phone"
                    :placeholder="$t('phone_placeholder')"
                    :hint="$t('phone_hint')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    prepend-inner-icon="fa-phone"
                    :label="$t('DriverList.phoneExt')"
                    filled
                    v-model="editDialogData.phoneExt"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('DriverList.gender')"
                    filled
                    :items="$t('gender')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.gender"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('DriverList.tag')"
                    filled
                    :items="$t('driverTag')"
                    v-model="editDialogData.tag"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    prepend-inner-icon="mdi-card-account-details-outline"
                    :label="$t('DriverList.driverLicenceNumber')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.driverLicenceNumber"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <my-datepicker
                    v-model="editDialogData.driverLicenceExpiration"
                    :title="$t('DriverList.driverLicenceExpiration')"
                    :label="$t('DriverList.driverLicenceExpiration')"
                    filled
                    required
                  >
                  </my-datepicker>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    prepend-inner-icon="mdi-card-account-details-outline"
                    :label="$t('DriverList.driverTaxiLicenceNumber')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.driverTaxiLicenceNumber"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <my-datepicker
                    v-model="editDialogData.driverTaxiLicenceExpiration"
                    :title="$t('DriverList.driverTaxiLicenceExpiration')"
                    :label="$t('DriverList.driverTaxiLicenceExpiration')"
                    filled
                    required
                  >
                  </my-datepicker>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('DriverList.language1')"
                    filled
                    :items="$t('language')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="editDialogData.language1"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('DriverList.language2')"
                    filled
                    :items="$t('language')"
                    v-model="editDialogData.language2"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('DriverList.language3')"
                    filled
                    :items="$t('language')"
                    v-model="editDialogData.language3"
                    required
                  ></v-select
                ></v-col>

                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :label="$t('DriverList.language4')"
                    filled
                    :items="$t('language')"
                    v-model="editDialogData.language4"
                    required
                  ></v-select
                ></v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="showEditDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="submitEditDialog()">{{
              $t("btnSave")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialog zmenu hesla -->
    <v-dialog v-model="showEditPasswordDialog" max-width="600px">
      <v-card
        ><v-form ref="formEditPassword">
          <v-card-title style="word-break: normal">
            {{ $t("DriverList.dialogPasswordChangeTitle") }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12"
                  >Oznámte prosím nové heslo vodičovi. Vodičovi nebude heslo
                  zaslané.
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" v-show="displayOnlyForAdmin">
                  <v-text-field
                    :label="$t('DriverList.id')"
                    v-model="editPasswordDialogData.item.id"
                    disabled
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    :label="$t('DriverList.password')"
                    prepend-inner-icon="fa-lock"
                    filled
                    :rules="passwordRules"
                    v-model="editPasswordDialogData.item.password"
                    required
                    :append-icon="
                      editPasswordDialogData.showPassword
                        ? 'mdi-eye'
                        : 'mdi-eye-off'
                    "
                    :type="
                      editPasswordDialogData.showPassword ? 'text' : 'password'
                    "
                    append-outer-icon="fa-random"
                    @click:append="
                      editPasswordDialogData.showPassword =
                        !editPasswordDialogData.showPassword
                    "
                    @click:append-outer="
                      (editPasswordDialogData.item.password =
                        Math.random().toString(36).substr(2, 3).toUpperCase() +
                        'x' +
                        Math.random().toString(36).substr(2, 5)),
                        (editPasswordDialogData.showPassword = true)
                    "
                    autocomplete="new-password"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="showEditPasswordDialog = false">
              {{ $t("btnClose") }}
            </v-btn>
            <v-btn color="green darken-1" text @click="submitChangePassword()">
              {{ $t("btnChange") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Dialog zmenu stavu vodica -->
    <v-dialog v-model="showEditStatusDialog" max-width="600px">
      <v-card
        ><v-form ref="formEditStatus">
          <v-card-title style="word-break: normal">
            {{ $t("DriverList.dialogEditStatusTitle") }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" v-show="displayOnlyForAdmin">
                  <v-text-field
                    :label="$t('DriverList.driverId')"
                    v-model="editStatusDialogData.driverId"
                    disabled
                    type="number"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('DriverList.firstName')"
                    v-model="editStatusDialogInfoData.firstName"
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('DriverList.lastName')"
                    v-model="editStatusDialogInfoData.lastName"
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="showEditStatusDialog = false">
              {{ $t("btnClose") }}
            </v-btn>
            <v-btn
              v-show="editStatusDialogInfoData.status == 'DISABLED'"
              color="green darken-1"
              text
              @click="submitChangeStatus(true)"
            >
              {{ $t("btnEnable") }}
            </v-btn>
            <v-btn
              v-show="editStatusDialogInfoData.status == 'ENABLED'"
              color="green darken-1"
              text
              @click="submitChangeStatus(false)"
            >
              {{ $t("btnDisable") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import ImportDataCsv from "../components/ImportDataCsv.vue";

export default {
  components: {
    ImportDataCsv,
  },
  data() {
    return {
      displayOnlyForAdmin: false,
      enabledDrivers: true,

      moment: moment,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      gridHeaders: [
        {
          text: this.$t("DriverList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("DriverList.tableHeaders.email"),
          value: "email",
          sortable: true,
          filter: (value) => {
            if (!this.filter.email) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.email.toLowerCase());
            }
          },
        },
        {
          text: this.$t("DriverList.tableHeaders.internalName"),
          value: "internalName",
          sortable: true,
          filter: (value) => {
            if (!this.filter.internalName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.internalName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("DriverList.tableHeaders.firstName"),
          value: "firstName",
          sortable: true,
          filter: (value) => {
            if (!this.filter.firstName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.firstName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("DriverList.tableHeaders.lastName"),
          value: "lastName",
          sortable: true,
          filter: (value) => {
            if (!this.filter.lastName) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.lastName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("DriverList.tableHeaders.phone"),
          value: "phone",
          sortable: true,
          filter: (value) => {
            if (!this.filter.phone) {
              return value === "" || value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.phone.toLowerCase());
            }
          },
        },
        {
          text: this.$t("DriverList.tableHeaders.phoneExt"),
          value: "phoneExt",
        },
        { text: this.$t("DriverList.tableHeaders.status"), value: "status" },
        { text: this.$t("DriverList.tableHeaders.active"), value: "active" },
        {
          text: this.$t("DriverList.tableHeaders.workStatus"),
          value: "workStatus",
        },
        { text: this.$t("DriverList.tableHeaders.silence"), value: "silence" },
        {
          text: this.$t("DriverList.tableHeaders.dispatcher"),
          value: "dispatcher",
        },
        {
          text: this.$t("DriverList.tableHeaders.external"),
          value: "external",
        },
        {
          text: this.$t("DriverList.tableHeaders.licenceStatus"),
          value: "licenceStatus",
        },
      ],
      gridItems: [],

      filter: {
        search: "",
        email: "",
        internalName: "",
        firstName: "",
        lastName: "",
        phone: "",
      },
      loadingData: false,
      //Dialogove okno s Vytvaranim novej polozky
      createDefaultItem: {
        email: "",
        internalName: "",
        firstName: "",
        lastName: "",
        phone: "",
        phoneExt: "",
        password: "",
        driverLicenceExpiration: null,
        driverLicenceNumber: null,
        driverTaxiLicenceExpiration: null,
        driverTaxiLicenceNumber: null,
      },

      createDialogData: {
        showPassword: false,
        item: {},
      },

      showCreateDialog: false,

      //Dialogove okno s Editovanim polozky
      editDialogData: {},
      showEditDialog: false,

      //Dialogove okno so zmenou hesla
      editDefaultPasswordItem: {
        id: "",
        password: "",
      },
      editPasswordDialogData: {
        showPassword: false,
        item: {
          id: null,
          password: null,
        },
      },
      showEditPasswordDialog: false,
      //Validacia hesla
      passwordRules: [
        (v) => !!v || this.$t("msgRequiredField"),
        (v) => v.length >= 6 || this.$t("msgPasswordMinLength"),
        (v) => /[a-z]/.test(v) || this.$t("msgPasswordLowerCase"),
        (v) => /[0-9]/.test(v) || this.$t("msgPasswordNumber"),
      ],

      //Dialogove okno so zmenou stavu
      editStatusDialogData: {
        driverId: null,
        enabled: null,
      },
      editStatusDialogInfoData: {
        firstName: null,
        lastName: null,
        status: null,
      },
      showEditStatusDialog: false,

      importColumnOptions: [
        {
          text: this.$t("DriverList.tableHeaders.email"),
          value: "email",
        },
        {
          text: this.$t("DriverList.tableHeaders.internalName"),
          value: "internalName",
        },
        {
          text: this.$t("DriverList.tableHeaders.firstName"),
          value: "firstName",
        },
        {
          text: this.$t("DriverList.tableHeaders.lastName"),
          value: "lastName",
        },
        {
          text: this.$t("DriverList.tableHeaders.phone"),
          value: "phone",
        },
        {
          text: this.$t("DriverList.tableHeaders.phoneExt"),
          value: "phoneExt",
        },
        {
          text: this.$t("DriverList.tableHeaders.password"),
          value: "password",
        },
        {
          text: this.$t("DriverList.tableHeaders.language1"),
          value: "language1",
        },
        {
          text: this.$t("DriverList.tableHeaders.gender"),
          value: "gender",
        },
      ],

      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  mounted() {
    //Nacitanie role podla ktorej sa potom zobrazuju niektore extra polia alebo tlacidla
    this.getUserRole();
    this.getAll();
  },
  watch: {
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },
  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    getAll() {
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/driver?enabled=${this.enabledDrivers}`
        )
        .then((response) => {
          this.loadingData = false;
          var data = response.data;
          data.forEach((element) => {
            if (
              element.driverLicenceExpiration === null ||
              element.driverLicenceExpiration < new Date().toISOString() ||
              element.driverLicenceNumber === null
            ) {
              element.licenceStatus = "NOKD";
            } else if (
              element.driverTaxiLicenceExpiration === null ||
              element.driverTaxiLicenceExpiration < new Date().toISOString() ||
              element.driverTaxiLicenceNumber === null
            ) {
              element.licenceStatus = "NOKT";
            } else {
              element.licenceStatus = "OK";
            }
          });
          this.gridItems = data;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    /* detail(id) {
      this.$router.push({ name: "pricelist-detail", params: { id: id } });
    },*/
    openEditPasswordDialog(id) {
      this.editPasswordDialogData.item = Object.assign(
        {},
        this.editDefaultPasswordItem
      );
      this.editPasswordDialogData.item.id = id;
      this.showEditPasswordDialog = true;
      if (this.$refs.formEditPassword) {
        this.$refs.formEditPassword.resetValidation();
      }

      this.editPasswordDialogData.showPassword = false;
    },

    submitChangePassword() {
      const isValid = this.$refs.formEditPassword.validate();
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/driver/password`,
            {
              driverId: this.editPasswordDialogData.item.id,
              password: this.editPasswordDialogData.item.password,
            }
          )
          .then((response) => {
            this.showEditPasswordDialog = false;
            this.snackbar.show = true;
            this.snackbar.message = this.$t("DriverList.msgPasswordChanged");
            this.snackbar.color = "success";
          })
          .catch((e) => {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("msgResponseError");
            this.snackbar.color = "error";
          });
      } else {
        this.snackbar.show = true;
        this.snackbar.message = this.$t("DriverList.msgInvalidDataInForm");
        this.snackbar.color = "error";
      }
    },

    openCreateDialog() {
      this.createDialogData.item = Object.assign({}, this.createDefaultItem);
      this.showCreateDialog = true;
      if (this.$refs.formCreate) {
        this.$refs.formCreate.resetValidation();
      }

      this.createDialogData.showPassword = false;
    },
    submitCreateDialog() {
      const isValid = this.$refs.formCreate.validate();
      if (isValid) {
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/driver`,
            this.createDialogData.item
          )
          .then((response) => {
            this.showCreateDialog = false;
            //this.detail(response.data.id);
            this.getAll(); // zatial len refresh nemame detail
          })
          .catch((e) => {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("msgResponseError");
            this.snackbar.color = "error";
          });
      }
    },
    openEditDialog(id) {
      if (this.$refs.formEdit) {
        this.$refs.formEdit.resetValidation();
        /*this.$nextTick(() => {
          if (this.$refs[formEdit]) {
            this.$refs.formEdit.resetValidation();
          }
        });*/
      }
      var gridItems;
      var selectedItem;
      gridItems = this.gridItems;
      selectedItem = Object.assign(
        {},
        gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      console.log("selectedItem", selectedItem);
      if (selectedItem) {
        this.editDialogData = selectedItem;
        this.showEditDialog = true;
      } else {
        console.log("viewLocalityOnDialogMap missing data problem");
        this.snackbar.show = true;
        this.snackbar.message = this.$t("DriverList.msgDataMissing");
        this.snackbar.color = "error";
      }
    },

    submitEditDialog() {
      const isValid = this.$refs.formEdit.validate();
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/driver`,
            this.editDialogData
          )
          .then((response) => {
            this.showEditDialog = false;
            //this.detail(response.data.id);
            this.getAll(); // zatial len refresh nemame detail
            this.snackbar.show = true;
            this.snackbar.message = this.$t("DriverList.msgDriverUpdated");
            this.snackbar.color = "success";
          })
          .catch((e) => {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("msgResponseError");
            this.snackbar.color = "error";
          });
      }
    },
    openEditStatusDialog(id) {
      if (this.$refs.formEditStatus) {
        this.$refs.formEditStatus.resetValidation();
        /*this.$nextTick(() => {
          if (this.$refs[formEdit]) {
            this.$refs.formEdit.resetValidation();
          }
        });*/
      }
      var gridItems;
      var selectedItem;
      gridItems = this.gridItems;
      selectedItem = Object.assign(
        {},
        gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      console.log("selectedItem", selectedItem);
      if (selectedItem) {
        this.editStatusDialogData.driverId = selectedItem.id;
        this.editStatusDialogInfoData.firstName = selectedItem.firstName;
        this.editStatusDialogInfoData.lastName = selectedItem.lastName;
        this.editStatusDialogInfoData.status = selectedItem.status;

        this.showEditStatusDialog = true;
      } else {
        this.snackbar.show = true;
        this.snackbar.message = this.$t("DriverList.msgDataMissing");
        this.snackbar.color = "error";
      }
    },
    submitChangeStatus(newStatus) {
      const isValid = this.$refs.formEditStatus.validate();
      this.editStatusDialogData.enabled = newStatus;
      console.log("submitChangeStatus", this.editStatusDialogData);
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/driver/status`,
            this.editStatusDialogData
          )
          .then((response) => {
            this.showEditStatusDialog = false;
            //this.detail(response.data.id);
            this.getAll(); // zatial len refresh nemame detail
            this.snackbar.show = true;
            this.snackbar.message = this.$t(
              "DriverList.msgDriverStatusUpdated"
            );
            this.snackbar.color = "success";
          })
          .catch((e) => {
            this.snackbar.show = true;
            this.snackbar.message = this.$t("msgResponseError");
            this.snackbar.color = "error";
          });
      }
    },

    handleImport(dataArray) {
      console.log("handleImport", dataArray);
      var createData = {};
      dataArray.forEach((element) => {
        if (element) {
          createData.email = element.email;
          createData.internalName = element.internalName;
          createData.firstName = element.firstName;
          createData.lastName = element.lastName;
          createData.phone = element.phone;
          createData.phoneExt = element.phoneExt;
          createData.password = element.password;
          createData.tag = null;
          createData.language1 = element.language1;
          createData.gender = element.gender;
          console.log(createData);
          axios
            .post(
              process.env.VUE_APP_API_BASE_URL + `/dispatching/driver`,
              createData
            )
            .then((response) => {
              console.log("import user success", response);
            })
            .catch((e) => {
              console.log("import user error", e);
            });
        }
      });
    },

    getStatusColor(status) {
      switch (status) {
        case "ENABLED":
          return "green";
        case "DISABLED":
          return "red";
        default:
          return "black";
      }
    },
    getActiveColor(status) {
      switch (status) {
        case "ACTIVE":
          return "green";
        case "DISABLED":
          return "red";
        default:
          return "black";
      }
    },
    getWorkStatusColor(status) {
      switch (status) {
        case "ONLINE":
          return "green";
        case "OFFLINE":
          return "red";
        case "ON_BREAK":
          return "orange";
        default:
          return "black";
      }
    },
  },
};
</script>
